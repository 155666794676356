type address = {
    id: string;
    address: string;
    phone: string;
    mobile: string;
}
const state = {
    UserAddress: [] as address[],
    viewAddress: null,
    Provinces: [''],
    ProvincesObject: null,
    citys: [''],
    citysObject: null,
    zones: [],
    zones_title: []
}

const mutations = {
    removeAddressUser: (state,paylod) => {
        const address = state.UserAddress.find(p => p.id == paylod)
        if (address) {
          const index =state.UserAddress.indexOf(address)
          state.UserAddress.splice(index, 1)
        }
      },
      setAddressUser: (state,paylod) => {
        state.UserAddress = paylod
      },
      viewAddressUser: (state,paylod) => {
        state.viewAddress = paylod
      },
      Provinces: (state,paylod) => {
        const Provinces = ['--استان خودرا انتخاب کنید--']
        for (const iterator of paylod) {
          Provinces.push(iterator.value)
        }
        state.Provinces = Provinces
        state.ProvincesObject = paylod
      },
      ProvinceCitys: (state,paylod) => {
        const citys = ['--شهر خودرا انتخاب کنید--']
        for (const iterator of paylod) {
          citys.push(iterator.value)
        }
        state.citys = citys
        state.citysObject = paylod
      },
      set_zone: (state, payload) => {
        state.zones = payload.zones
        state.zones_title = payload.title
      }
}

const actions = {
      removeAddressUser: ({commit}, paylod) => {
        commit('removeAddressUser', paylod)
      },
      setAddressUser: ({commit}, paylod) => {
        commit('setAddressUser',paylod)
      },
      viewAddressUser: ({commit}, paylod) => {
        commit('viewAddressUser',paylod)
      },
      Provinces: ({commit}, paylod) => {
        commit('Provinces',paylod)
      },
      ProvinceCitys: ({commit}, paylod) => {
        commit('ProvinceCitys',paylod)
      },
      set_zone: ({commit}, payload) => {
        let zones = [] as any
        let zones_title = [] as any
        for (const zone of payload) {
          const _zone = {
            title: zone.Zone_Title,
            id: zone.Zone_ID_PK
          }
          zones_title.push(zone.Zone_Title)
          zones.push(_zone)
        }
          commit("set_zone", {zones:zones, title: zones_title})
      }
}

export default { state, mutations, actions }