
const state = {
    send_code: false,
    fild_register: [
      'first_name','last_name','mobile','password','invited','code'
    ]
  }
  
  const mutations = {
    send_code_forget: (state,paylod) => {
          state.send_code = paylod
        },
  }
  
  const actions = {
    send_code_forget: ({commit}, paylod) => {
          commit('send_code_forget',paylod)
    },
  }
  
  export default { state, mutations, actions }