import { get_config } from '@/models/changeType';
import '@mdi/font/css/materialdesignicons.css'
import axios from 'axios';
import Vue from 'vue';
import Vuetify from 'vuetify';
Vue.use(Vuetify);
get_config()
let config = {} as any
config = localStorage.getItem('config')
const v_config = localStorage.getItem('config_v')
if (!config && !v_config || v_config != '6') {
  get_config()
}
const _config = JSON.parse(config)
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('dynamic-favicon');
    link.id = 'dynamic-style';
    link.rel = 'stylesheet';
    link.href = `/template/mobile/style/${_config.client_id}.css`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
  // <link rel="apple-touch-icon" sizes="57x57" href="/apple-icon-57x57.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('apple-icon-57x57')
    link.id = 'apple-icon-57x57'
    link.rel = 'apple-touch-icon'
    link.href = `/template/mobile/images/icons/${_config.client_id}/apple-icon-57x57.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
  // <link rel="apple-touch-icon" sizes="60x60" href="/apple-icon-60x60.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('apple-icon-60x60');
    link.id = 'apple-icon-60x60';
    link.rel = 'apple-touch-icon';
    link.href = `/template/mobile/images/icons/${_config.client_id}/apple-icon-60x60.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
  // <link rel="apple-touch-icon" sizes="72x72" href="/apple-icon-72x72.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('apple-icon-72x72');
    link.id = 'apple-icon-72x72';
    link.rel = 'apple-touch-icon';
    link.href = `/template/mobile/images/icons/${_config.client_id}/apple-icon-72x72.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
  // <link rel="apple-touch-icon" sizes="76x76" href="/apple-icon-76x76.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('apple-icon-76x76');
    link.id = 'apple-icon-76x76';
    link.rel = 'apple-touch-icon';
    link.href =  `/template/mobile/images/icons/${_config.client_id}/apple-icon-76x76.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
  // <link rel="apple-touch-icon" sizes="114x114" href="/apple-icon-114x114.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('apple-icon-114x114');
    link.id = 'apple-icon-114x114';
    link.rel = 'apple-touch-icon';
    link.href =  `/template/mobile/images/icons/${_config.client_id}/apple-icon-114x114.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
  // <link rel="apple-touch-icon" sizes="120x120" href="/apple-icon-120x120.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('apple-icon-120x120');
    link.id = 'apple-icon-120x120';
    link.rel = 'apple-touch-icon';
    link.href = `/template/mobile/images/icons/${_config.client_id}/apple-icon-120x120.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
  // <link rel="apple-touch-icon" sizes="144x144" href="/apple-icon-144x144.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('apple-icon-144x144');
    link.id = 'apple-icon-144x144';
    link.rel = 'apple-touch-icon';
    link.href = `/template/mobile/images/icons/${_config.client_id}/apple-icon-144x144.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
  // <link rel="apple-touch-icon" sizes="152x152" href="/apple-icon-152x152.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('apple-icon-152x152');
    link.id = 'apple-icon-152x152';
    link.rel = 'apple-touch-icon';
    link.href = `/template/mobile/images/icons/${_config.client_id}/apple-icon-152x152.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
  // <link rel="apple-touch-icon" sizes="180x180" href="/apple-icon-180x180.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('apple-icon-180x180');
    link.id = 'apple-icon-180x180';
    link.rel = 'apple-touch-icon';
    link.href = `/template/mobile/images/icons/${_config.client_id}/apple-icon-180x180.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
  // <link rel="icon" type="image/png" sizes="192x192"  href="/android-icon-192x192.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('android-icon-192x192');
    link.id = 'android-icon-192x192';
    link.rel = 'icon';
    link.href =`/template/mobile/images/icons/${_config.client_id}/android-icon-192x192.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
  // <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('favicon-32x32');
    link.id = 'favicon-32x32';
    link.rel = 'icon';
    link.type = "image/png"
    link.href = `/template/mobile/images/icons/${_config.client_id}/favicon-32x32.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }


  // <link rel="icon" type="image/png" sizes="96x96" href="/favicon-96x96.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('favicon-96x96');
    link.id = 'favicon-96x96';
    link.rel = 'icon';
    link.type = "image/png"
    link.href = `/template/mobile/images/icons/${_config.client_id}/favicon-96x96.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }


  // <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png">
  if (_config) {
    let link = document.createElement('link'),
    oldLink = document.getElementById('favicon-16x16');
    link.id = 'favicon-16x16';
    link.rel = 'icon';
    link.type = "image/png"
    link.href = `/template/mobile/images/icons/${_config.client_id}/favicon-16x16.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }


  // <meta name="msapplication-TileColor" content="#ffffff">
  if (_config) {
    let link = document.createElement('meta'),
    oldLink = document.getElementById('msapplication-TileColor');
    link.id = 'msapplication-TileColor';
    link.name = 'msapplication-TileColor';
    link.content = "#ffffff"
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }

  // <meta name="msapplication-TileImage" content="/ms-icon-144x144.png">
  if (_config) {
    let link = document.createElement('meta'),
    oldLink = document.getElementById('dynamic-favicon');
    link.id = 'dynamic-style';
    link.name = 'msapplication-TileImage'
    link.content = `/template/mobile/images/icon/${_config.client_id}/ms-icon-144x144.png`
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }

  // <meta name="theme-color" content="#ffffff">
  if (_config) {
    let link = document.createElement('meta'),
    oldLink = document.getElementById('dynamic-favicon');
    link.id = 'dynamic-style';
    link.name = 'theme-color'
    link.content = "#5ec5bd"
  if (oldLink) {
  document.head.removeChild(oldLink);
  }
  document.head.appendChild(link);
  }
export default new Vuetify({
    rtl: true,
    icons: {
      iconfont: 'mdiSvg',
    },
    theme: {
      themes: {
        // light: themes.light
        light: 
        {
          accent: "#D9F4F4",
accentText: "#ffffff",
actionNavColor: "#fff",
background: "#f3f7e8",
darkAccent: "#a7c1c1",
darkDisable: "#7E7C7C",
darkPrimary: "#23948d",
darkSecondary: "#b10007",
disableColor: "#E0E0E0",
error: "#ec202f",
errorBg: "#F9E3E5",
footerBg: "#D9F4F4",
headerColor: "#fff",
info: "#1E88E5",
lightAccent: "#ffffff",
lightDisable: "#FAFAFA",
lightError: "#FFD5D5",
lightPrimary: "#92f8ef",
lightSecondary: "#ff6159",
lightSuccess: "#D9F2DD",
offLabel: "#0e7b46",
onAccent: "#055B31",
onActionNav: "#055B31",
onBackground: "#212121",
onDarkAccent: "#212121",
onDarkPrimary: "#fff",
onDarkSecondary: "#212121",
onFooter: "#212121",
onHeader: "#ffffff",
onLightAccent: "#212121",
onLightPrimary: "#212121",
onLightSecondary: "#212121",
onOffLabel: "#fff",
onPrimary: "#fff",
onPrimaryActions: "#fff",
onSecondHeader: "#fff",
onSecondary: "#212121",
onSecondaryActions: "#ec202f",
onSurface: "#484848",
primary: "#5ec5bd",
primaryActions: "#5ec5bd",
primaryText: "#055B31",
secondHeader: "#5ec5bd",
secondary: "#ec202f",
secondaryActions: "#f8f8f8",
secondaryText: "#055B31",
success: "#0e7b46",
successBg: "#E1F9EE",
surface: "#fff",
warning: "#FFA000",
warningBg: "#FBEAD0",
      }
      }
    }
});